import { useStore } from "../Store";
import { useEffect } from "react";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { IconAi, IconBrain, IconCheck } from "@tabler/icons-react";
import { Button, Group, Image, Text, Loader } from "@mantine/core";
import {client} from "../api/axiosClient"



export function StructureConsultData() {
 

  const {
    setCommandGetHypotheses,
    setCurrentNotification,
    consultationTranscription,
    setObservationValues,
  } = useStore();

  const fetchData = async () => {
   try{

    if (consultationTranscription){
    notifications.clean()
    const notificationId = notifications.show({
        withBorder: true,
        loading: false,
        radius: "lg",
        color: "#94ADA7",
        title: (
          <Group gap="xs">
            <Image
              fit="contain"
              src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
              alt="iAvicenne Logo"
              width={25}
              height={25}
              style={{ margin: '0', display: 'block' }}
            />
            <Text fw={700}>Structuration en Cours</Text>
            <Loader className="ChatLoader" color="#94ADA7" type="dots" />
          </Group>
        ),
        opacity: 0.7,
        autoClose: false,
        withCloseButton: true,
      });
      setCurrentNotification(notificationId);
    
    const response = await client.get(`api/structure/?transcription=${consultationTranscription}`)
    if (response.status === 200){
       setObservationValues(response.data.transcription)
       if (response.data.transcription.Synthese.Resume.length>0){
        setCommandGetHypotheses(true)
       }
       console.log(response.data.transcription)
       
    }else(console.log(response))

   }}catch(error){
    console.log(error)
   }
  };
  useEffect(() => {
    fetchData();
  }, [consultationTranscription]);
  
}
