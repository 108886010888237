import { Group, Text, rem } from '@mantine/core';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons-react';
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from '@mantine/dropzone';


export function Signature() {
    return (
        <Dropzone
          onDrop={(files) => console.log('accepted files', files)}
          onReject={(files) => console.log('rejected files', files)}
          maxSize={1 * 1024 ** 2}
          accept={IMAGE_MIME_TYPE}
       
        >
          <Group justify="center" gap="xl" h={"12vh"} radius="14px" style={{ pointerEvents: 'none' }} >
            <Dropzone.Accept>
              <IconUpload
                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                stroke={1.5}
              />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX
                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                stroke={1.5}
              />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconPhoto
                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }}
                stroke={1.5}
              />
            </Dropzone.Idle>
    
            <div>
              <Text size="xl" inline>
                deposer l'image ou clickez ici
              </Text>
              <Text size="sm" c="dimmed" inline mt={7}>
                l'image de votre signature doit avoir un arrière plan blanc
              </Text>
            </div>
          </Group>
        </Dropzone>
      );
}