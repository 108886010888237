import { useEffect, useState } from "react";
import { useStore } from "../Store";
import {client} from "../api/axiosClient"

export function useAuth() {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const {currentUser,setCurrentUser,setCurrentSessionInfo, setPractitioner, setOrdonnanceEntete} = useStore();

  useEffect(() => {
    let isMounted = true;
  
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const res = await client.get("api/check-auth/");
        if (isMounted) {
          setCurrentUser(res.data.user);
          // Appel à api/current-session/
          try {
            const sessionRes = await client.get(`api/current-session/?practitioner=${res.data.user.id}`);
            if (sessionRes.status === 200) {
              setCurrentSessionInfo({
                practitioner: res.data.user.id,
                subject: sessionRes.data.subject,
                encounter: null,
              });
            } else {
              setCurrentSessionInfo({
                practitioner: res.data.user.id,
                subject: null,
                encounter: null,
              });
            }
          } catch (sessionError) {
            console.error("Erreur lors de la récupération de la session courante:", sessionError);
            setCurrentSessionInfo({
              practitioner: res.data.user.id,
              subject: null,
              encounter: null,
            });
          }
        }
      } catch (error) {
        if (isMounted) {
          setCurrentUser(false);
          setError(error.response?.data?.message || "Une erreur est survenue lors de la vérification de l'authentification");
        }
      } finally {
        if (isMounted) setLoading(false);
      }
    };
  
    fetchData();
  
    return () => {
      isMounted = false;
    };
  }, []);


  useEffect(()=>{
    const fetchPractitioner = async () => {
      try{
        const response = await client.get(`api/practitioners/${currentUser.id}/`);
        if(response.status===200){
          setPractitioner(response.data)
        }
      }catch(error){
        console.log(error)
      }
    };
    const fetchOrdonnanceEntete = async () => {
      try{
        const response = await client.get(`api/entete/${currentUser.id}/`);
        if(response.status===200){
          setOrdonnanceEntete(response.data)
        }
      }catch(error){
        console.log(error)
      }
    };
    if (currentUser.id){
      fetchPractitioner()
      fetchOrdonnanceEntete()
    }
  },[currentUser]);


  const logout = async () => {
    try {
      setLoading(true);
      await client.post("api/logout/");
      setCurrentUser(false);
      setCurrentSessionInfo(null);
    } catch (error) {
      setError("Erreur lors de la déconnexion");
    } finally {
      setLoading(false);
    }
  };

  return { currentUser, setCurrentUser, loading, error, logout };

}