import React, { useEffect, useState, useCallback } from "react";
import { Select, Loader, Group, Image, Text} from "@mantine/core";
import { IconAlertTriangleFilled, IconUserSearch } from "@tabler/icons-react";
import { useStore } from "../Store";
import {client} from "../api/axiosClient"
import { notifications } from "@mantine/notifications";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

async function getPatient(query = "") {
  const response = await client.get(`api/patients/search/?name=${query}`);
 
  if (response.status===200){console.log(response)}
  if (response.status !== 200) throw new Error("Network response was not ok");
  return response.data;
}

export function SearchPatientRapprochement(documentId, mailId) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setquery] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const { mailboxData, setMailboxData, practitioner } = useStore();


  const debouncedGetPatient = useCallback(
    debounce(async (query) => {
      try {
        const patients = await getPatient(query);
        const formattedData = patients.patients.map((patient) => ({
          value: `${patient.id}`,
          label: `${patient.NomNaissance} ${patient.UsagePrenom}`,
          patient: patient,
        }));
        setData(formattedData);
        // console.log({'formattedData' : formattedData})
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch patient:", error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (query) {
      debouncedGetPatient(query);
    } else {
      setData([]);
    }
  }, [query, debouncedGetPatient]);



  const selectCurrentPatient = async (patient) => {
   try{
    const [docResponse, mailResponse] = await Promise.all([
        client.patch(`api/documents/${documentId.documentId}/`, { patient: patient.patient.id  }),
        client.patch(`api/mssante/${documentId.mailId}/`, { patient: patient.patient.id  })
      ]);
      if (docResponse.status === 200 && mailResponse.status === 200) {
        notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                <Image
                  fit="contain"
                  src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                  alt="iAvicenne Logo"
                  width={25}
                  height={25}
                  style={{ margin: "0", display: "block" }}
                />
                <Text fw={700}>Rapprochement Fait avec succès</Text>
              </Group>
            ),
            opacity: 0.7,
            autoClose: true,
            withCloseButton: true,
          });
          return true; // Indiquer que la sauvegarde a réussi
        } else {
          notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                <IconAlertTriangleFilled color="red" size="25" />
                <Text fw={700}>Erreur</Text>
                <Text size="sm">
                  "lors du Rapprochement données patient:"
                  {docResponse.status} {docResponse.statusText}
                  {mailResponse.status} {mailResponse.statusText}
                </Text>
              </Group>
            ),
            opacity: 0.7,
            autoClose: true,
            withCloseButton: true,
          });
     

      }

   }catch(error){
    notifications.show({
        withBorder: true,
        loading: false,
        radius: "lg",
        color: "#94ADA7",
        title: (
          <Group gap="xs">
            <IconAlertTriangleFilled color="red" size="25" />
            <Text fw={700}>Erreur</Text>
            <Text size="sm">
              "lors du Rapprochement données patient:"
              {error.response.status} {error.response.statusText}
            </Text>
          </Group>
        ),
        opacity: 0.7,
        autoClose: true,
        withCloseButton: true,
      });
   }
   
  };
  return (
    <Select
    // className="patientSelect"
    // classNames={{
    //   input: 'patientSelect-input',
    // }}
    
      searchable
      searchValue={query}
      onSearchChange={setquery}
      value={selectedValue}
      placeholder="Rapprochement Patient Manuel"
      data={data}
      loading={loading && <Loader size="sm" />}
    
      rightSection={<IconUserSearch className="patientSearchIcon" color="#fff" size="25px" style={{ translate:-15 }} />}
      maxDropdownHeight={200}
      onChange={(id, value) => {
        if (value) {
          selectCurrentPatient(value);
          setSelectedValue(null);
          setquery("");
          setData([]);
        }
      }}
      comboboxProps={{
        transitionProps: { transition: "pop", duration: 200 },
        shadow: "md",
        width: "300px",
        radius:"md",
      }}
    
      style={{ width: "300px",shadow: "md"}}
    />
  );
}


