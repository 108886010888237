// EnableTwoFactorComponent.jsx

import { useState } from "react";
import { client } from "../api/axiosClient";
import { QRCodeCanvas } from "qrcode.react";
import { useStore } from "../Store";
import {
  Button,
  Text,
  Stack,
  Group,
  Card,
  PinInput,
  Indicator,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconAlertTriangleFilled, IconCheck } from "@tabler/icons-react";

export function EnableTwoFactorComponent() {
  const [qrCode, setQrCode] = useState(null);
  const [otpCode, setOtpCode] = useState("");
  const [message, setMessage] = useState("");
  const { practitioner, currentUser } = useStore();
  const provisioning_uri = "";


  const Notification = (status, text) => {
    notifications.show({
      withBorder: true,
      loading: false,
      radius: "lg",
      color: "#94ADA7",
      title: (
        <Group gap="xs">
          {status === "Erreur" ? (
            <IconAlertTriangleFilled color="red" size="25" />
          ) : (
            <IconCheck color="green" size="25" />
          )}
          <Text fw={700}>{status}</Text>
          <Text size="sm">{text}</Text>
        </Group>
      ),
      opacity: 0.7,
      autoClose: 2000,
      withCloseButton: true,
    });
  };

  const enableTwoFactor = async () => {
    try {
      const response = await client.get("/api/enable-two-factor/");
      if (response.status === 200) {
        if (response.data.provisioning_uri) {
          setQrCode(response.data.provisioning_uri);
          const provisioning_uri = response.data.provisioning_uri;
        }  else {
          Notification("Erreur", response.data.detail);
        }
      } else console.log(response);
    } catch (error) {
      console.error(error);
      Notification("Erreur", "lors de l’activation du 2FA.");
    }
  };

  const verifyTwoFactor = async () => {
    try {
      const response = await client.post("/api/verify-two-factor/", {
        code: otpCode,
      });
      if (response.status === 200) {
        Notification("Succès", "2FA activé avec succès.");
      }
    } catch (error) {
      console.error(error);
      Notification("Erreur", "Code OTP invalide.");
    }
  };

  return (
    <Stack>
      {!practitioner.is_two_factor_enabled ? (
        !qrCode ? (
          <Stack>
          <Group>
          <IconAlertTriangleFilled color="red" />
          <Text fw={700} ta="left">Authentification à deux facteurs non activée</Text>
        </Group>
          <Stack gap="sm" justify="stretch" align="center" >
           
            <Text size="sm">
              Pour mieux sécuriser votre compte, L'authentification a deux
              facteurs est obligatoire pour la connexion par mot de passe. Si vous ne l'activez pas vous ne pourrez pas vous connecter en cas de panne du service ProSanteConnect
            </Text>
            <Button
              className="printButton"
              variant="light"
              size="md"
              color="#94ADA7"
              radius="12px"
              onClick={enableTwoFactor}
              fullWidth
              disabled = {currentUser.password?.length<7}
              mt="10"
            >
              Activer l’authentification à deux facteurs
            </Button>
          </Stack>
          </Stack>
        ) : (
          <Stack gap="md" justify="stretch" align="center">
            {/* <Text size="xs">
              Scannez ce code QR avec votre application d'authentification :
            </Text> */}
            <Card withBorder w="max-content" shadow>
              <QRCodeCanvas value={qrCode} size={150} minVersion={10} />
            </Card>

            <Text size="xs">Scannez ce code QR avec votre application d'authentification puis Entrez le code OTP</Text>
            <PinInput
              aria-label="One time code"
              value={otpCode}
              length={6}
              onChange={setOtpCode}
            />
            <Button
              className="printButton"
              variant="light"
              size="sm"
              color="#94ADA7"
              radius="12px"
              onClick={verifyTwoFactor}
              w="70%"
              m="10px"
            >
              Vérifier
            </Button>
          </Stack>
          
        )
      ) : (
        <Button
          className="printButton"
          variant="light"
          size="md"
          color="#94ADA7"
          radius="12px"
          onClick={enableTwoFactor}
          fullWidth
        >
          <Group>
            <Text> Authentification à deux facteurs active </Text>{" "}
            <Indicator
              color="green"
              radius="lg"
              withBorder
              processing
              size="14px"
              m="5px"
            />
          </Group>
        </Button>
      )}
    </Stack>
  );
}
