// components/NavBar.js

import { useEffect, useRef } from "react";
import { Group, Button, Text } from "@mantine/core";
import {
  IconMessageFilled,
  IconMailFilled,
  IconUserCheck,
  IconStethoscope,
  IconPlus,
  IconStethoscopeOff,
  IconCheck,
  IconUserPlus,
  IconSettingsFilled,
  IconSchool,
} from "@tabler/icons-react";
import { useFullscreen } from "@mantine/hooks";
import { useStore } from "../Store";
import Logout from "../components/Logout";
import { StructureConsultData } from "../components/StructureClinique";
import { SearchPatient } from "../components/SearchPatient";
import { SearchPatientfromCV } from "../components/SearchPatientFromCV";
import { useEncounter } from "../hooks/useEncounter";
import { IsearchAntecedents } from "../components/AutoSearchAntecedents";

export function NavBar() {
  const { toggle, fullscreen } = useFullscreen();
  const {
    setMiddleActive,
    setMiddleActiveTab,
    setActive,
    setActiveTab,
    selectedPatientInfo,
    currentSessionInfo,
    showEncouterObjects,
    setShowPatientCard,
    setAgendaOpened,
    setMailboxOpened,
    setSelectedPatientInfo,
    setShowDashboard,
    setShowEncouterObjects,
    showLoaderX,
    loaderXMessage,
    showPatientCard,
    showDashboard,
    agendaOpened,
    mailboxOpened,
    setRecognition,
    isSessionActive,
    setCommandStartSession,
    getMiddleData,
    setShowLoaderX,
    commandGetBeneficiaires,
    setCommandGetBeneficiaires,
    setCommandRecordWebSearch,
    setCommandRecordConsult,
    setCommandSaveRecordConsult,
    setCommandSaveRecord,
    webVariant,
    scholarVariant,
    pubmedVariant,
    setChat,
    setWebVariant,
    setScholarVariant,
    setPubmedVariant,
    setCommandChooseBeneficiaires,
  } = useStore();

  const middleData = getMiddleData();

  const { CreateEncounter, SaveEncounter } = useEncounter();
  const demarrerConsultRef = useRef(null);
  const cloturerConsultRef = useRef(null);
  const handleSimulateClickCloturerConsultation = () => {
    if (cloturerConsultRef.current) {
      // Simuler un clic sur le bouton
      cloturerConsultRef.current.click();
    }
  };
  const handleSimulateClickDemarrerConsultation = () => {
    if (demarrerConsultRef.current) {
      // Simuler un clic sur le bouton
      demarrerConsultRef.current.click();
    }
  };

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech recognition not supported in this browser.");
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.lang = "fr-FR";

    recognition.onresult = (event) => {
      const lastResult = event.results[event.results.length - 1];
      if (lastResult.isFinal) {
        const command = lastResult[0].transcript.trim().toLowerCase();
        console.log("Voice Command Detected:", command);
        if (command.includes("avicenne") && !isSessionActive) {
          setCommandStartSession(true);
          setShowPatientCard(true);
          setMiddleActive("iAvicenne");
          setMiddleActiveTab("iAvicenne");
          setAgendaOpened(false);
          setMailboxOpened(false);
          setShowDashboard(false);
          setShowLoaderX(false);
        }
        if (command.includes("faire une recherche") && !isSessionActive) {
          handleChatClick();
        }
        if (
          (command.includes("recherche pubmed") ||
            command.includes("recherche sur pubmed")) &&
          !isSessionActive
        ) {
          handleChatClick();
          setChat("showPubmedChat");
          setWebVariant("light");
          setScholarVariant("light");
          setPubmedVariant("filled");
        }
        if (command.includes("recherche sémantique") && !isSessionActive) {
          handleChatClick();
          setChat("showScholarChat");
          setWebVariant("light");
          setScholarVariant("filled");
          setPubmedVariant("light");
        }
        if (
          (command.includes("recherche web") ||
            command.includes("recherche sur le web")) &&
          !isSessionActive
        ) {
          handleChatClick();
          setChat("showAudioChat");
          setWebVariant("filled");
          setScholarVariant("light");
          setPubmedVariant("light");
        }

        if (command.includes("enregistre la question") && !isSessionActive) {
          setCommandRecordWebSearch(true);
        }
        if (command.includes("lance la recherche") && !isSessionActive) {
          setCommandSaveRecord(true);
        }
        if (
          (command.includes("console principale") ||
            command.includes("console centrale")) &&
          !isSessionActive
        ) {
          handleConsoleClick2();
        }
        if (
          (command.includes("lis la carte vitale") ||
            command.includes("lire la carte vitale")) &&
          !isSessionActive
        ) {
          setCommandGetBeneficiaires(true);
        }
        if (command.includes("patient") && !isSessionActive) {
          // Supprime le mot "choisi" (insensible à la casse) et supprime les espaces en trop
          const cleanedCommand = command.replace(/patient\s*/i, "").trim();
          setCommandChooseBeneficiaires(cleanedCommand);
        }
        if (command.includes("choisi") && !isSessionActive) {
          // Supprime le mot "choisi" (insensible à la casse) et supprime les espaces en trop
          const cleanedCommand = command.replace(/choisi\s*/i, "").trim();
          setCommandChooseBeneficiaires(cleanedCommand);
        }
        if (command.includes("démarre la consultation") && !isSessionActive) {
          handleSimulateClickDemarrerConsultation();
        }
        if (
            (( command.includes("consentement ok") )||
            (command.includes("le patient consent à l'enregistrement")) ||
            (command.includes("consentement du patient accordé"))  ||
            (command.includes("j'ai receuilli le consentement du patient" )) ||
            (command.includes("démarre l'enregistrement" ) )||
            (command.includes( "consentement du patient"))||
            (command.includes("démarre") && command.includes("enregistrement"))||
            (command.includes("consentement") && command.includes("patient"))&&
            !isSessionActive)
        ) {
          setCommandRecordConsult(true);
        }
        if (command.includes("valide l'enregistrement") && !isSessionActive) {
          setCommandSaveRecordConsult(true);
        }
        if (command.includes("clôture la consultation") && !isSessionActive) {
          handleSimulateClickCloturerConsultation();
        }
        if (command.includes("nouveau patient") && !isSessionActive) {
          handleNewPatientFormControlChange();
        }
        if ((command.includes("modifier le patient"))||(command.includes("modifier les données du patient"))&& !isSessionActive) {
          handlePatientFormControlChange();
        }
        if (command.includes("mes mails") && !isSessionActive) {
          setMailboxOpened(true);
          setAgendaOpened(false);
          setShowPatientCard(false);
          setShowDashboard(false);
        }
        if (command.includes("changer les paramètres") && !isSessionActive) {
          handleHomeClick();
        }

        if ((command.includes("montre l'historique"))||(command.includes("montre-moi l'historique")|| 
        (command.includes("montre") && command.includes("historique"))|| 
        (command.includes("affiche") && command.includes("historique"))) && !isSessionActive) {
          setMiddleActive("Historique des Consultations");
          setMiddleActiveTab("Historique des Consultations");
        }
        if((command.includes("montre les documents"))||
        (command.includes("montre-moi les documents")|| 
        (command.includes("montre") && command.includes("document"))|| 
        (command.includes("affiche") && command.includes("document")))  && !isSessionActive) {
          setMiddleActive("Documents");
          setMiddleActiveTab("Documents");
        }
        if ((command.includes("montre l'observation"))||
        (command.includes("montre-moi l'observation")|| 
        (command.includes("montre") && command.includes("observation"))|| 
        (command.includes("affiche") && command.includes("observation")))  && !isSessionActive) {
          setMiddleActive("Observation Médicale");
          setMiddleActiveTab("Observation Médicale");
        }
        if ((command.includes("montre le courrier"))||
        (command.includes("montre-moi le courrier")|| 
        (command.includes("montre") && command.includes("courrier"))|| 
        (command.includes("affiche") && command.includes("courrier")))  && !isSessionActive) {
          setMiddleActive("Courrier Médical");
          setMiddleActiveTab("Courrier Médical");
        }
        if ((command.includes("montre les hypothèses"))||
        (command.includes("montre-moi les hypothèses")|| 
        (command.includes("montre") && command.includes("hypothèse"))|| 
        (command.includes("affiche") && command.includes("hypothèse")))  && !isSessionActive) {
          setMiddleActive("Hypotheses Diagnostiques");
          setMiddleActiveTab("Hypotheses Diagnostiques");
        }
        if ((command.includes("montre les examens"))||
        (command.includes("montre-moi les examens")|| 
        (command.includes("montre") && command.includes("examen"))|| 
        (command.includes("affiche") && command.includes("examen")))  && !isSessionActive) {
          setMiddleActive("Examens Complementaires");
          setMiddleActiveTab("Examens Complementaires");
        }
        if ((command.includes("montre les traitements"))||
        (command.includes("montre-moi les traitements")|| 
        (command.includes("montre") && command.includes("traitement"))|| 
        (command.includes("affiche") && command.includes("traitement")))  && !isSessionActive) {
          setMiddleActive("Propositions Thérapeutiques");
          setMiddleActiveTab("Propositions Thérapeutiques");
        }
        if ((command.includes("ordonnance des médicaments") ||
         (command.includes("ordonnance") && command.includes("médicament"))) && !isSessionActive) {
          setActive("medicaments");
          setActiveTab("medicaments");
      }
      if ((command.includes("ordonnance des dispositifs") ||
       (command.includes("ordonnance") && command.includes("dispositif"))) && !isSessionActive) {
        
          setActive("dispositifsMedicaux");
          setActiveTab("dispositifsMedicaux");
        }
        if ((command.includes("ordonnance de biologie") ||
         (command.includes("ordonnance") && command.includes("biologie"))) && !isSessionActive) {
          setActive("biologie");
          setActiveTab("biologie");
        }
        if ((command.includes("ordonnance de radiologie") ||
         (command.includes("ordonnance") && command.includes("dispositif"))) && !isSessionActive) {
          setActive("radiologie");
          setActiveTab("radiologie");
        }
        if ((command.includes("ordonnance") && command.includes("autre")) && !isSessionActive) {
          setActive("autres");
          setActiveTab("autres");
        }
      }
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
    };

    recognition.onend = () => {
      recognition.start(); // Restart listening
    };

    recognition.start();

    // Stocker l'instance dans le store
    setRecognition(recognition);

    return () => {
      recognition.stop();
      recognition.onend = null;
    };
  }, [setRecognition]);

  const handleHomeClick = () => {
    setSelectedPatientInfo("");
    setAgendaOpened(false);
    setMailboxOpened(false);
    setShowPatientCard(false);
    setShowDashboard(true);
  };

  const handlePatientFormControlChange = () => {
    setShowPatientCard(true);
    setMiddleActive("Données Patient");
    setMiddleActiveTab("Données Patient");
    setAgendaOpened(false);
    setMailboxOpened(false);
    setShowDashboard(false);
  };

  const handleNewPatientFormControlChange = () => {
    setShowPatientCard(true);
    setMiddleActive("Nouveau Patient");
    setMiddleActiveTab("Nouveau Patient");
    setAgendaOpened(false);
    setMailboxOpened(false);
    setShowDashboard(false);
  };

  const handleChatClick = () => {
    setShowPatientCard(true);
    setMiddleActive("iAvicenne Research");
    setMiddleActiveTab("iAvicenne Research");
    setAgendaOpened(false);
    setMailboxOpened(false);
    setShowDashboard(false);
  };

  const handleConsoleClick = () => {
    setShowPatientCard(true);
    setMiddleActive("realtimeConsole");
    setMiddleActiveTab("realtimeConsole");
    setAgendaOpened(false);
    setMailboxOpened(false);
    setShowDashboard(false);
  };

  const handleConsoleClick2 = () => {
    setShowPatientCard(true);
    setMiddleActive("iAvicenne");
    setMiddleActiveTab("iAvicenne");
    setAgendaOpened(false);
    setMailboxOpened(false);
    setShowDashboard(false);
  };

  return (
    <Group className="navBar" justify="space-between">
      <Group>
        {selectedPatientInfo && currentSessionInfo.subject && (
          <Button
            className="connectedButton"
            variant="filled"
            radius="12px"
            color="#94ADA7"
            onClick={handlePatientFormControlChange}
          >
            <IconUserCheck size={25} />
            <Text size="sm">
              <strong>
                &nbsp;&nbsp;
                {selectedPatientInfo.NomNaissance}{" "}
                {selectedPatientInfo.UsagePrenom}
              </strong>
            </Text>
          </Button>
        )}
        <SearchPatientfromCV />
        <Button
          className="connectedButton"
          variant="filled"
          radius="12px"
          color="#94ADA7"
          onClick={handleNewPatientFormControlChange}
        >
          <IconUserPlus size={25} />
        </Button>
        <StructureConsultData />
        <IsearchAntecedents />
        <SearchPatient />

        {selectedPatientInfo &&
          currentSessionInfo.subject &&
          (showEncouterObjects ? (
            <Button
              ref={cloturerConsultRef}
              className="connectedButton"
              variant="filled"
              color="#94ADA7"
              radius="12px"
              size="sm"
              onClick={SaveEncounter}
            >
              <Group spacing="xs">
                <IconStethoscopeOff size={23} />
                Clôturer
                <IconCheck size={18} />
              </Group>
            </Button>
          ) : (
            <Button
              ref={demarrerConsultRef}
              className="connectedButton"
              variant="filled"
              color="#94ADA7"
              radius="12px"
              size="sm"
              onClick={CreateEncounter}
            >
              <Group spacing="xs">
                <IconStethoscope size={23} />
                Démarrer
                <IconPlus size={18} />
              </Group>
            </Button>
          ))}
      </Group>

      {showLoaderX && (
        <Group>
          <div className="loaderX"></div>
          <Text c="#54545470" size="xs">
            {loaderXMessage}...
          </Text>
        </Group>
      )}

      <Group>
        <Button
          className="connectedButton"
          variant="filled"
          radius="12px"
          color="#94ADA7"
          onClick={handleConsoleClick2}
        >
          <IconMessageFilled />
          {/* <Text size="sm">&nbsp;Realtime</Text> */}
        </Button>

        <Button
          className="connectedButton"
          variant="filled"
          radius="12px"
          color="#94ADA7"
          onClick={handleChatClick}
        >
          <IconSchool />
          {/* <Text size="sm">&nbsp;iA Research</Text> */}
        </Button>
        <Button
          className="connectedButton"
          variant="filled"
          radius="12px"
          color="#94ADA7"
          onClick={() => {
            setMailboxOpened(true);
            setAgendaOpened(false);
            setShowPatientCard(false);
            setShowDashboard(false);
          }}
        >
          <IconMailFilled />
          {/* <Text size="sm">&nbsp;Mssanté</Text> */}
        </Button>
        <Button
          className="connectedButton"
          variant="filled"
          radius="12px"
          color="#94ADA7"
          onClick={handleHomeClick}
        >
          <IconSettingsFilled />
          {/* <Text size="sm">&nbsp;Paramètres</Text> */}
        </Button>
        <Logout />
      </Group>
    </Group>
  );
}
