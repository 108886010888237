

import {
  Stack,
  Button,
  Grid,
  Text,
  Title,
  Card,
  Group,
  Fieldset,
  Indicator,
  Divider,
  Skeleton,
  Tabs,
  SegmentedControl,
  Blockquote,
} from "@mantine/core";
import logo from "../logoPSC.svg";
import logo6 from "../logo_olaqin.webp";
import logo3 from "../Logo_Mon_espace_sante.png";
import logo4 from "../ameli_pro_1.png";
import logo2 from "../MSSante-vertical-quadri.png";
import logo5 from "../illus1-apcv.png";
import { useStore } from "../Store";
import codesProfession from "../data/MosProfessionSante.json";
import codesSavoirFaire from "../data/MosSavoirFAire.json";
import { EnableTwoFactorComponent } from "./ EnableTwoFactorComponent";
import { ChangePasswordComponent } from "./ChangePasseword";
import { Abonnements } from "./Abonnements";
import { Signature } from "./Signature";
import { notifications } from "@mantine/notifications";
import { useState, useEffect } from "react";
import {

  IconBlockquote,
  IconCrown,
  IconCurrencyEuro,
  IconShield,
  IconShieldPlus,

} from "@tabler/icons-react";

export function PractitionerDashboard() {
  const { practitioner, ordonnanceEntete, currentUser } = useStore();
  const [loading, setLoading] = useState(false);
  const [activeTabPassword, setActiveTabPassword] = useState("ChangePassword");
  const [activePassword, setActivePassword] = useState("ChangePassword");

  const dataPassword = [
    {
      value: "ChangePassword",
      label: (
        <Group style={{ gap: "0.5rem" }} justify="center">
          <IconShield size="16" />
          <Text size="xs">Mot de passe</Text>
        </Group>
      ),
    },
    {
      value: "2FA",
      label: (
        <Group style={{ gap: "0.5rem" }} justify="center">
          <IconShieldPlus size="16" />
          <Text size="xs">authentification 2FA</Text>
        </Group>
      ),
    },
  ];
  

  useEffect(() => {
    // Simulons un appel API pour charger les données
    const fetchData = async () => {
      try {
        // Simule un délai pour le chargement des données
        await new Promise((resolve) => setTimeout(resolve, 2000));
        // Met à jour les données ici si nécessaire
      } catch (error) {
        console.error("Erreur lors du chargement des données", error);
      } finally {
        // Une fois toutes les données chargées, on désactive le mode "loading"
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const getCategorie = (url) => {
    const segments = url.split("-");
    return segments[segments.length - 1];
  };
  const handlePasswordControlChange = (value) => {
    setActivePassword(value);
    setActiveTabPassword(value);
  };


  return (
    <Stack gap="xs" style={{ padding: "2vw" }}>
      <Grid
        grow
        gutter="xs"
        type="container"
        breakpoints={{
          xs: "100px",
          sm: "200px",
          md: "300px",
          lg: "400px",
          xl: "500px",
        }}
      >
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p="lg">
          <Card shadow="lg" withBorder radius="12px" style={{ height: "40vh" }}>
            <Fieldset
              legend={
                <Title order={4} ta="left" c="gray">
                  Données CPS du Médecin
                </Title>
              }
              radius="12px"
              ta="left"
            >
              <Skeleton height={"100%"} radius="12px" visible={loading}>
                {" "}
                <Group gap="xs">
                  <Text>Dr</Text>{" "}
                  <Text tt="capitalize">
                    {practitioner?.given_name?.toLowerCase()}
                  </Text>
                  <Text>{practitioner?.family_name}</Text>
                  <Text>{practitioner?.preferred_username}</Text>
                </Group>
                <Divider my="xs" label="Qualifications" labelPosition="left" />
                <Text>
                  {codesProfession?.compose?.include[0]?.concept?.map(
                    (concept, index) =>
                      concept.code ===
                        practitioner?.SubjectRefPro?.exercices[0]
                          ?.codeProfession && concept.display
                  )}
                </Text>
                <Text>
                  {codesSavoirFaire?.concept?.map(
                    (concept, index) =>
                      concept.code ===
                        practitioner?.SubjectRefPro?.exercices[0]
                          ?.codeSavoirFaire && concept.display
                  )}
                </Text>
              </Skeleton>
            </Fieldset>

            <Fieldset
              legend={
                <Title order={4} ta="left" c="gray">
                  Données Annuaire Santé
                </Title>
              }
              radius="12px"
              ta="left"
              style={{ height: "100%" }}
            >
              <Skeleton height={"100%"} mt={10} radius="12px" visible={loading}>
                <Stack>
                  <Text>{practitioner.annuaire_sante?.MSSAddresses}</Text>
                  <Divider my="0px" label="Diplômes" labelPosition="left" />

                  <Text>
                    {practitioner.annuaire_sante?.Qualifications?.map(
                      (qualif) => (
                        <Text>{qualif.display}</Text>
                      )
                    )}
                  </Text>
                  <Divider my="0px" label="Adresse" labelPosition="left" />

                  <Group gap="xs">
                    {practitioner.annuaire_sante?.CabinetAddress.line.map(
                      (item) => (
                        <Text>{item}</Text>
                      )
                    )}
                    <Text>
                      {practitioner.annuaire_sante?.CabinetAddress.postalCode}
                    </Text>
                    <Text>
                      {practitioner.annuaire_sante?.CabinetAddress.city}
                    </Text>
                  </Group>
                </Stack>
              </Skeleton>
            </Fieldset>
          </Card>
        </Grid.Col>

       
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p="lg">
          <Card shadow="lg" withBorder radius="12px" style={{ height: "40vh" }}>
            <Fieldset
              legend={
                <Title order={4} ta="left" c="gray">
                  Sécurité
                </Title>
              }
              radius="12px"
              ta="left"
              style={{ height: "100%" }}
            >
              <Tabs
                value={activeTabPassword}
                onChange={setActiveTabPassword}
                radius="12px"
              >
                <SegmentedControl
                  data={dataPassword}
                  value={activePassword}
                  onChange={handlePasswordControlChange}
                  color="#94ADA7"
                  radius="12px"
                  size="xs"
                  withItemsBorders={false}
                  transitionDuration={500}
                  transitionTimingFunction="linear"
                  fullWidth
                  style={{ marginBottom: 20 }}
                />
                <Tabs.Panel value="ChangePassword">
                  <Skeleton height={"100%"} radius="12px" visible={loading}>
                    <ChangePasswordComponent />
                  </Skeleton>
                </Tabs.Panel>
                <Tabs.Panel value="2FA">
                  <Skeleton height={"100%"} radius="12px" visible={loading}>
                    <EnableTwoFactorComponent />
                  </Skeleton>
                </Tabs.Panel>
              </Tabs>

              <Stack></Stack>
            </Fieldset>
          </Card>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p="lg">
          <Card shadow="lg" withBorde radius="12px" style={{ height: "40vh" }}>
            <Fieldset
              legend={
                <Title order={4} ta="left" c="gray">
                  Abonnements
                </Title>
              }
              radius="12px"
              ta="left"
              style={{ height: "100%" }}
            >
              <Skeleton height={"100%"} radius="12px" visible={loading}>
               <Abonnements/>
              </Skeleton>
            </Fieldset>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid style={{ height: "40vh" }}>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p="lg">
          <Card shadow="lg" withBorder radius="12px" style={{ height: "40vh" }}>
            <Fieldset
              legend={
                <Title order={4} ta="left" c="gray">
                  Entêtes des Ordonnances
                </Title>
              }
              radius="12px"
              ta="left"
              style={{ height: "100%" }}
            >
              <Skeleton height={"100%"} radius="12px" visible={loading}>
                <Stack gap="xs" align="flex-start">
                  <Group>
                    <Text fw={700} c="grey">
                      Ligne 2:{" "}
                    </Text>
                    <Text>{ordonnanceEntete.specialite}</Text>
                  </Group>
                  <Group>
                    <Text fw={700} c="grey">
                      Ligne 3:{" "}
                    </Text>
                    <Text>{ordonnanceEntete.info_ordo}</Text>
                  </Group>
                  <Group>
                    <Text fw={700} c="grey">
                      Ligne 4:{" "}
                    </Text>
                    <Text>{ordonnanceEntete.addresse}</Text>
                  </Group>
                  <Group>
                    <Text fw={700} c="grey">
                      Ligne 5:{" "}
                    </Text>
                    <Text>{ordonnanceEntete.fixe}</Text>
                  </Group>
                  <Group>
                    <Text fw={700} c="grey">
                      Ligne 6:{" "}
                    </Text>
                    <Text>{ordonnanceEntete.rendez_vous}</Text>
                  </Group>
                </Stack>
              </Skeleton>
            </Fieldset>
            <Fieldset
              legend={
                <Title order={4} ta="left" c="gray">
                  Aperçu
                </Title>
              }
              radius="12px"
              ta="left"
              style={{ height: "100%" }}
            >
              {" "}
              <Skeleton
                height={"100%"}
                radius="12px"
                visible={loading}
              ></Skeleton>
            </Fieldset>
          </Card>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p="lg">
          <Card shadow="lg" withBorde radius="12px" style={{ height: "40vh" }}>
            <Fieldset
              legend={
                <Title order={4} ta="left" c="gray">
                  Signature
                </Title>
              }
              radius="12px"
              ta="left"
              style={{ height: "100%" }}
            >
             <Signature/>
              <Skeleton
                height={"100%"}
                radius="12px"
                visible={loading}
              ></Skeleton>
            </Fieldset>
          </Card>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }} p="lg">
          <Card shadow="lg" withBorder radius="12px" style={{ height: "40vh" }}>
            <Fieldset
              legend={
                <Title order={4} ta="left" c="gray">
                  Services Tiers
                </Title>
              }
              radius="12px"
              ta="left"
              style={{ height: "100%" }}
            >
              <Skeleton
                height={"100%"}
                radius="12px"
                visible={loading}
                style={{ zIndex: 5000 }}
              >
                {!loading && (
                  <Grid>
                    <Grid.Col span={9}>
                      <Button
                        className="TiersButton"
                        size="lg"
                        variant="light"
                        radius="12px"
                        color="#94ADA7"
                        fullWidth
                      >
                        <a href="https://wallet.esw.esante.gouv.fr/auth/?response_type=code&client_id=iavicenne&redirect_uri=https://www.iavicenne.fr/api/auth-redirect/&scope=openid%20scope_all&acr_values=eidas1">
                          <img
                            src={logo}
                            className="App-logo"
                            alt="logo"
                            height="30px"
                          />
                        </a>
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Group justify="end" m="10px">
                        <Text>Connecté</Text>

                        <Indicator
                          color="green"
                          radius="lg"
                          withBorder
                          processing
                          size="14px"
                          m="5px"
                        />
                      </Group>
                    </Grid.Col>

                    <Grid.Col span={9}>
                      <Button
                        className="TiersButton"
                        variant="light"
                        size="lg"
                        radius="12px"
                        color="#94ADA7"
                        fullWidth
                      >
                        <a href="">
                          <img
                            src={logo2}
                            className="App-logo"
                            alt="logo"
                            height="40px"
                          />
                        </a>
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Group justify="end" m="10px">
                        <Text>Connecté</Text>
                        <Indicator
                          color="green"
                          radius="lg"
                          withBorder
                          processing
                          size="14px"
                          m="5px"
                        />
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <Button
                        className="TiersButton"
                        variant="light"
                        size="lg"
                        radius="12px"
                        color="#94ADA7"
                        fullWidth
                      >
                        <Group justify="space-between" gap="lg">
                          <a href="">
                            <img
                              src={logo3}
                              className="App-logo"
                              alt="logo"
                              height="40px"
                            />
                          </a>
                        </Group>
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Group justify="end" m="10px">
                        <Text>En cours</Text>
                        <Indicator
                          color="orange"
                          radius="lg"
                          withBorder
                          processing
                          size="14px"
                          m="5px"
                        />
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <Button
                        className="TiersButton"
                        variant="light"
                        size="lg"
                        radius="12px"
                        color="#94ADA7"
                        fullWidth
                      >
                        <Group justify="space-between" gap="lg">
                          <a href="">
                            <img
                              src={logo4}
                              className="App-logo"
                              alt="logo"
                              height="80px"
                            />
                          </a>
                        </Group>
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Group justify="end" m="10px">
                        <Text>En Cours </Text>
                        <Indicator
                          color="orange"
                          radius="lg"
                          withBorder
                          processing
                          size="14px"
                          m="5px"
                        />
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <Button
                        className="TiersButton"
                        variant="light"
                        size="lg"
                        radius="12px"
                        color="#94ADA7"
                        fullWidth
                      >
                        <Group justify="space-between" gap="lg">
                          <a href="">
                            <img
                              src={logo5}
                              className="App-logo"
                              alt="logo"
                              height="50px"
                            />
                          </a>
                        </Group>
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Group justify="end" m="10px">
                        <Text>En Cours</Text>
                        <Indicator
                          color="orange"
                          radius="lg"
                          withBorder
                          processing
                          size="14px"
                          m="5px"
                        />
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <Button
                        className="TiersButton"
                        variant="light"
                        size="lg"
                        radius="12px"
                        color="#94ADA7"
                        fullWidth
                      >
                        <Group justify="space-between" gap="lg">
                          <a href="https://auth.demo.stellair.fr/login?response_type=code&client_id=6ttqsb20njjuef20lcdjd670kp&scope=openid%20email&state=&redirect_uri=https://www.iavicenne.fr/api/olaqin-auth/&nonce=">
                            <img
                              src={logo6}
                              className="App-logo"
                              alt="logo"
                              height="40px"
                            />
                          </a>
                        </Group>
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Group justify="end" m="10px">
                        <Text>En Cours</Text>
                        <Indicator
                          color="orange"
                          radius="lg"
                          withBorder
                          processing
                          size="14px"
                          m="5px"
                        />
                      </Group>
                    </Grid.Col>
                  </Grid>
                )}
              </Skeleton>
            </Fieldset>
          </Card>
        </Grid.Col>
      </Grid>
    </Stack>
  );
}
