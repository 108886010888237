import { useEffect, useRef, useState, useCallback } from "react";
import EventLog from "./EventLog";
import SessionControls from "./SessionControls";
import ToolPanel from "./ToolPanel";
import { client } from "../api/axiosClient";
import { Card, Group, Text, Stack, ScrollArea } from "@mantine/core";
import { AudioVisualizer } from "../components/Audiovisualizer";
import Markdown from "react-markdown";
import { useStore } from "../Store";


export function ConsolePage() {
  // const [isSessionActive, setIsSessionActive] = useState(false);
  const [events, setEvents] = useState([]);
  const [dataChannel, setDataChannel] = useState(null);
  const [micStream, setMicStream] = useState(null);
  const [systemStream, setSystemStream] = useState(null);
  const [streamedText, setStreamedText] = useState("");
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef(null);

  const peerConnection = useRef(null);
  const audioElement = useRef(null);

  const {
    setShowPatientCard,
    setAgendaOpened,
    setMailboxOpened,
    setShowDashboard,   
    setMiddleActive,
    setMiddleActiveTab,
    setShowLoaderX,
    setLoaderXMessage,
    isSessionActive,
    setIsSessionActive,
    commandStartSession,
    setCommandStartSession,
    setIsActivatingSession,
    getMiddleData} = useStore();

  const middleData = getMiddleData();


  async function startSession() {
    setLoaderXMessage("activation")
    setIsActivatingSession(true)
    setShowLoaderX(true)

    // Get an ephemeral key from the Fastify server
    const tokenResponse = await client.get(`api/ephemeral/`);
    const data = tokenResponse.data.client_secret.value;
    const EPHEMERAL_KEY = data;

    // Create a peer connection
    const pc = new RTCPeerConnection();

    // Set up to play remote audio from the model
    audioElement.current = document.createElement("audio");
    audioElement.current.autoplay = true;
    pc.ontrack = (e) => {
      // On assigne le flux reçu à l'élément audio
      audioElement.current.srcObject = e.streams[0];

      // Ajouter le setState pour le visualiseur système
      setSystemStream(e.streams[0]);
    };

    // Add local audio track for microphone input in the browser
    const ms = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    pc.addTrack(ms.getTracks()[0]);
    setMicStream(ms);
    // Set up data channel for sending and receiving events
    const dc = pc.createDataChannel("oai-events");
    setDataChannel(dc);

    // Start the session using the Session Description Protocol (SDP)
    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);
    console.log(offer)

    const baseUrl = "https://api.openai.com/v1/realtime";
    const model = "gpt-4o-mini-realtime-preview-2024-12-17";
    const sdpResponse = await fetch(`${baseUrl}?model=${model}`, {
      method: "POST",
      body: offer.sdp,
      headers: {
        Authorization: `Bearer ${EPHEMERAL_KEY}`,
        "Content-Type": "application/sdp",
      },
    });

    const answer = {
      type: "answer",
      sdp: await sdpResponse.text(),
    };
    await pc.setRemoteDescription(answer);
    peerConnection.current = pc;
    setShowPatientCard(true);
    setMiddleActive("iAvicenne");
    setMiddleActiveTab("iAvicenne");
    setAgendaOpened(false);
    setMailboxOpened(false);
    setShowDashboard(false)
    setShowLoaderX(false)
    // setIsActivatingSession(false)

  }
 
  useEffect(() => {
    if (commandStartSession && !isSessionActive) {
      startSession();
      // On remet commandStartSession à false pour éviter de boucler
      setCommandStartSession(false);
    }
  }, [commandStartSession, isSessionActive, startSession, setCommandStartSession]);

  // Stop current session, clean up peer connection and data channel
  function stopSession() {
    setShowLoaderX(true)
    setLoaderXMessage("Desactivation")
    
    if (dataChannel) {

      dataChannel.close();
    }
    
    if (peerConnection.current) {
      peerConnection.current.close();
    }
  
    setIsSessionActive(false);
    setDataChannel(null);
    peerConnection.current = null;
    
    setShowLoaderX(false)
  }

  // Send a message to the model
  function sendClientEvent(message) {
    if (dataChannel) {
      message.event_id = message.event_id || crypto.randomUUID();
      dataChannel.send(JSON.stringify(message));
      setEvents((prev) => [message, ...prev]);
    } else {
      console.error("Failed to send message - no data channel available", message);
    }
  }

  // Send a text message to the model
  function sendTextMessage(message) {
    const event = {
      type: "conversation.item.create",
      item: {
        type: "message",
        role: "user",
        content: [
          {
            type: "input_text",
            text: message,
          },
        ],
      },
    };
    setShowPatientCard(true);
    setMiddleActive("iAvicenne");
    setMiddleActiveTab("iAvicenne");
    setAgendaOpened(false);
    setMailboxOpened(false);
    setShowDashboard(false)
    sendClientEvent(event);
    sendClientEvent({ type: "response.create" });
  }

  useEffect(() => {
    if (!dataChannel) return;
  
    const handleMessage = (e) => {
      const eventData = JSON.parse(e.data);
      console.log(eventData);
  
      // 1. Ajouter un saut de ligne si l'événement correspond
      if (
        eventData.type === "conversation.item.created" ||
        eventData.type === "session.update" ||
        eventData.type === "response.done"
      ) {
        setStreamedText((prev) => prev + "\n");
      }
  
      // 2. Accumuler le texte des messages de type delta
      if (eventData.type === "response.audio_transcript.delta" || 
          eventData.type === "response.text.delta") {
        setStreamedText((prev) => prev + (eventData.delta || ""));
      }
  
      // 3. Ajouter l'événement complet à la liste des événements
      setEvents((prev) => [eventData, ...prev]);
    };
  
    dataChannel.addEventListener("message", handleMessage);
    dataChannel.addEventListener("open", () => {
      setIsSessionActive(true);
      setEvents([]);
      setStreamedText(""); // Réinitialiser le texte accumulé
      // sessionUpdate();
    });
  
    // Nettoyer l’event listener au démontage ou quand dataChannel change
    return () => {
      dataChannel.removeEventListener("message", handleMessage);
    };
  }, [dataChannel]);
  const scrollRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      const viewport = scrollContainer.querySelector('.mantine-ScrollArea-viewport');
      if (viewport) {
        viewport.scrollTo({
          top: viewport.scrollHeight,
          behavior: 'auto'
        });
      }
    }
  }, []);

  // Utiliser un useEffect avec un MutationObserver
  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const observer = new MutationObserver(() => {
      scrollToBottom();
    });

    observer.observe(scrollContainer, {
      childList: true,
      subtree: true,
      characterData: true
    });

    return () => observer.disconnect();
  }, [scrollToBottom]);

  // Scroll aussi quand streamedText change
  useEffect(() => {
    scrollToBottom();
  }, [streamedText, scrollToBottom]);

  return (
    <>
      <Stack>
        <Card
          className="content-block conversation"
          radius="12px"
          shadow="md"
          p="2vw"
          h={"75vh"}
        >
         
       
        <div ref={scrollRef}>
          <ScrollArea
            h={"65vh"}
            mt={"3vh"}
            offsetScrollbars
            scrollbarSize={6}
            type="always"
          >
              <Text ta="left" >
                <Markdown>{streamedText}</Markdown>
              </Text>
        
          </ScrollArea>
        </div>

          <Group>
            <div className="audioVisualizer_client">
              {isSessionActive && systemStream && (
                <AudioVisualizer audioStream={systemStream} />
              )}
            </div>
            <div className="audioVisualizer_system">
              {isSessionActive && micStream && (
                <AudioVisualizer audioStream={micStream} />
              )}
            </div>
          </Group>
        </Card>

        <SessionControls
          startSession={startSession}
          stopSession={stopSession}
          sendClientEvent={sendClientEvent}
          sendTextMessage={sendTextMessage}
          events={events}
          isSessionActive={isSessionActive}
        />
      </Stack>

      <ToolPanel
        // activateSound={activateSound}
        // deactivateSound={deactivateSound}
        sendClientEvent={sendClientEvent}
        sendTextMessage={sendTextMessage}
        stopSession={stopSession}
        events={events}
        isSessionActive={isSessionActive}
      // on peut en plus passer sendTextMessage, sendClientEvent, etc.
      />

    </>
  );
}

