import { client } from "../api/axiosClient";
import { notifications } from "@mantine/notifications";
import { Button, Group, Image, Text } from "@mantine/core";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import { useStore } from "../Store";

export const AddPatientFromMail = (values) => {
  const { setSelectedPatientInfo } = useStore();

  // Helper function to safely format date
  const formatDate = (dateString) => {
    if (!dateString) return null;

    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        // Check if date is valid
        console.log("Invalid date value:", dateString);
        return null;
      }
      return date.toISOString().split("T")[0];
    } catch (error) {
      console.log("Error formatting date:", error);
      return null;
    }
  };
  const handleSubmit = async () => {
    console.log(values.values);
    try {
      const PatientFormData = {
        NomNaissance: values.values.NomNaissance || "",
        UsagePrenom: values.values.UsagePrenom || "",
        birthDate: values.values.birthDate || null,
        gender: values.values.gender || "",
        INS: values.values.INS || "",
        NumIdentifiant: values.values.NumIdentifiant
          ? parseInt(values.values.NumIdentifiant, 10)
          : null,
        Cle: values.values.Cle ? parseInt(values.values.Cle, 10) : null,
        OID: values.values.OID || "",
        telecom: {
          Email: values.values.Email || "",
          Portable: values.values.Portable || "",
          Fixe: values.values.Fixe || "",
        },
        address: {
          Adresse: values.values.Adresse || "",
          CodePostale: values.values.CodePostale || "",
          Ville: values.values.Ville || "",
        },
        commentaire: values.values.commentaire || "",
        ListePrenom: values.values.ListePrenom || "",
        maritalStatus: values.values.maritalStatus || "",
        LieuNaissance: values.values.LieuNaissance
          ? parseInt(values.values.LieuNaissance, 10)
          : null,
        contact: values.values.contact ? [values.values.contact] : [],
        generalPractitioner: values.values.generalPractitioner || "",
        multipleBirthBoolean: values.values.multipleBirthBoolean || false,
        deceasedBoolean: values.values.deceasedBoolean || false,
      };

      const response = await client.post(`api/patients/`, PatientFormData, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 201) {
        setSelectedPatientInfo(response.data);
        notifications.show({
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <Image
                fit="contain"
                src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                alt="iAvicenne Logo"
                width={25}
                height={25}
                style={{ margin: "0", display: "block" }}
              />
              <Text fw={700}>Nouveau Patient enregistré</Text>
            </Group>
          ),
          opacity: 0.7,
          autoClose: true,
          withCloseButton: true,
        });
        return true; // Indiquer que la sauvegarde a réussi
      } else {
        notifications.show({
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <IconAlertTriangleFilled color="red" size="25" />
              <Text fw={700}>Erreur</Text>
              <Text size="sm">
                "lors de la sauvegarde du formulaire données patient:"
                {response.status} {response.statusText}
              </Text>
            </Group>
          ),
          opacity: 0.7,
          autoClose: true,
          withCloseButton: true,
        });

        return false; // Indiquer que la sauvegarde a échoué
      }
    } catch (error) {
      notifications.show({
        withBorder: true,
        loading: false,
        radius: "lg",
        color: "#94ADA7",
        title: (
          <Group gap="xs">
            <IconAlertTriangleFilled color="red" size="25" />
            <Text fw={700}>Erreur</Text>
            <Text size="sm">
              {error.response
                ? `Erreur lors de la sauvegarde du formulaire données patient: ${error.response.status} ${error.response.statusText}`
                : `Erreur lors de la sauvegarde du formulaire données patient: ${error.message}`}{" "}
            </Text>
          </Group>
        ),
        opacity: 0.7,
        autoClose: true,
        withCloseButton: true,
      });
      return false; // Indiquer que la sauvegarde a échoué
    }
  };
  return (
    <Button
      className="courrierButton"
      variant="light"
      color="#94ADA7"
      radius="12px"
      onClick={handleSubmit}
    >
      Créer un Nouveau Patient
    </Button>
  );
};
