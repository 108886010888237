import React, {useState} from "react";
import logo from "../logoPSC.svg";
import { Button, Text, Stack } from "@mantine/core";
import { IconArrowDown } from "@tabler/icons-react";

export function Login() {
  const [loading, setLoading] = useState(false);
  return (
    <div className="loginCard">
      <Stack align="center" justify="center" gap="xs" size={16}>
        <Text className="loginTitle" c="#94ADA7" fw={500} style={{fontSize:33, fontFamily:"Palatino"}}>
          Login
        </Text>
        {/* <IconArrowDown color="#94ADA7" size={16} className="arrowLogin"/> */}
        <Button
          className="PSC-button"
          variant="outline"
          radius="12px"
          color="#94ADA7"
          loading={loading}
        >
          <div>
            <a href="https://wallet.esw.esante.gouv.fr/auth/?response_type=code&client_id=iavicenne&redirect_uri=https://www.iavicenne.fr/api/auth-redirect/&scope=openid%20scope_all&acr_values=eidas1">
              <img src={logo} className="App-logo" alt="logo" />
            </a>
          </div>
        </Button>
      </Stack>
    </div>
  );
}


