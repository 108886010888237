import {create} from 'zustand'
import { Group, Text } from '@mantine/core'
import { IconPills, IconDroplet, IconRadioactive, IconEdit , IconTimelineEvent, IconFolderOpen, IconMessage, IconStethoscope, IconBrain, IconRadioactiveFilled, IconBandage, IconMail} from '@tabler/icons-react'

export const useStore = create(
// persist (
    (set,get) => ({

      currentUser: [],
      setCurrentUser: (info) => set({ currentUser: info }),

      practitioner: [],
      setPractitioner: (info) => set({ practitioner: info }),

      recognition: null,
      setRecognition: (instance) => set({ recognition: instance }),

      isSessionActive: false,
      setIsSessionActive: (value) => set({ isSessionActive: value }),

      isActivatingSession: false,
      setIsActivatingSession: (value) => set({ isActivatingSession: value }),

      commandStartSession: false,
      setCommandStartSession: (value) => set({ commandStartSession: value }),

      commandGetBeneficiaires: false,
      setCommandGetBeneficiaires: (value) => set({ commandGetBeneficiaires: value }),

      commandChooseBeneficiaires: "",
      setCommandChooseBeneficiaires: (text) => set({ commandChooseBeneficiaires: text }),
      
      commandRecordConsult: false,
      setCommandRecordConsult: (value) => set({ commandRecordConsult: value }),

      commandSaveRecordConsult: false,
      setCommandSaveRecordConsult: (value) => set({ commandSaveRecordConsult: value }),

      commandRecordWebSearch: false,
      setCommandRecordWebSearch: (value) => set({ commandRecordWebSearch: value }),

      commandPauseRecord: false,
      setCommandPauseRecord: (value) => set({ commandPauseRecord: value }),

      commandStopRecord: false,
      setCommandStopRecord: (value) => set({ commandStopRecord: value }),

      commandSaveRecord: false,
      setCommandSaveRecord: (value) => set({ commandSaveRecord: value }),

      commandGetHypotheses: false,
      setCommandGetHypotheses: (value) => set({ commandGetHypotheses: value }),

      ordonnanceEntete: [],
      setOrdonnanceEntete: (info) => set({ ordonnanceEntete: info }),

      selectedPatientInfo: [],
      setSelectedPatientInfo: (info) => set({ selectedPatientInfo: info }),

      currentSessionInfo: [],
      setCurrentSessionInfo: (info) => set({ currentSessionInfo: info }),currentSessionInfo :{
        practitioner:null,
        subject:null,
        encounter:null,
      },

      agendaOpened: false,
      setAgendaOpened: (value) => set({ agendaOpened: value }),

      mailboxOpened: false,
      setMailboxOpened: (value) => set({ mailboxOpened: value }),

      showPatientCard: false,
      setShowPatientCard: (value) => set({ showPatientCard: value }),

      loading: false,
      setLoading: (value) => set({ loading: value }),

      showLoaderX: false,
      setShowLoaderX: (value) => set({ showLoaderX: value }),

      loaderXMessage: "",
      setLoaderXMessage: (value) => set({ loaderXMessage: value }),

      shouldSaveCourrier: false,
      setShouldSaveCourrier: (value) => set({ shouldSaveCourrier: value }),

      showEncouterObjects: false,
      setShowEncouterObjects: (value) => set({ showEncouterObjects: value }),

      showDashboard: false,
      setShowDashboard: (value) => set({ showDashboard: value }),

      ordonnanceData: [],
      setOrdonnanceData: (newData) => set({ ordonnanceData: newData, activeTab: "medicaments" }),

      ordonnancetypeData: [],
      setOrdonnancetypeData: (newData) => set({ ordonnancetypeData: newData}),

      ordonnanceDuplicataData: [],
      setOrdonnanceDuplicataData: (newData) => set({ ordonnanceDuplicataData: newData}),

      ordonnanceFondData: [],
      setOrdonnanceFondDataData: (newData) => set({ ordonnanceFondData: newData}),

      biologieData: [],
      setBiologieData: (data) => set({ biologieData: data }),

      radiologieData: [],
      setRadiologieData: (data) => set({ radiologieData: data }),

      dispositifMedicalData: [],
      setDispositifMedicalData: (data) => set({ dispositifMedicalData: data }),

      AntecedentData: [],
      setAntecedentData: (data) => set({ AntecedentData: data }),

      observationData: [],
      setObservationData: (data) => set({ observationData: data }),

      courrierData: [],
      setCourrierData: (data) => set({ courrierData: data }),

      mailboxData: [],
      setMailboxData: (data) => set({ mailboxData: data }),

      ordonnanceLibreData: [],
      setOrdonnanceLibreData: (data) => set({ ordonnanceLibreData: data }),

      ordonnanceLibreDate:new Date(),
      setOrdonnanceLibreDate: (date) => set({ ordonnanceLibreDate: date }),

      historiqueData: [],
      setHistoriqueData: (data) => set({ historiqueData: data }),

      activeEncounter: [],
      setActiveEncounter: (value) => set({ activeEncounter: value }),

      antecedentSearchType: "Medical",
      setAntecedentSearchType: (type) => set({ antecedentSearchType: type }),

      showAntecedentsSearch: false,
      setShowAntecedentsSearch: (value) => set({ showAntecedentsSearch: value }),

      consultationTranscription: "",
      setConsultationTranscription: (text) => set({ consultationTranscription: text }),

      currentNotification: [],
      setCurrentNotification: (info) => set({ currentNotification: info }),

      observationValues: [],
      setObservationValues: (info) => set({ observationValues: info }),

      hypothesesDiagnostiques: [],
      setHypothesesDiagnostiques: (info) => set({ hypothesesDiagnostiques: info }),

      propositionsTherapeutiques: [],
      setPropositionsTherapeutiques: (info) => set({ propositionsTherapeutiques: info }),

      examensComplementaires: [],
      setExamensComplementaires: (info) => set({ examensComplementaires: info }),

      selectedMedicamentDetail: [],
      setSelectedMedicamentDetail: (info) => set({ selectedMedicamentDetail: info }),

      // filesToUpload: [],
      // setFilesToUpload: (files) => set({ filesToUpload: files }),

      posologie: [],
      setPosologie: (info) => set({ posologie: info }),

      editorState: [],
      setEditorState: (info) => set({ editorState: info }),

      newEditorState: [],
      setNewEditorState: (info) => set({ newEditorState: info }),

      documents: [],
      setDocuments: (info) => set({ documents: info }),

      chat: "showAudioChat",
      setChat: (text) => set({ chat: text }),

      webVariant: "light",
      setWebVariant: (text) => set({ webVariant: text }),

      scholarVariant: "light",
      setScholarVariant: (text) => set({ scholarVariant: text }),

      pubmedVariant: "light",
      setPubmedVariant: (text) => set({ pubmedVariant: text }),


      active: "medicaments",
      activeTab : "medicaments",
      data: [
        { 
          value: "medicaments", 
          label: (
            <Group style={{ gap: '0.5rem' }} justify="center">
              <IconPills size="16" />
              <Text size="xs">Médicaments</Text>
            </Group>
          ),
        },
        { 
          value: "dispositifsMedicaux", 
          label: (
            <Group style={{ gap: '0.5rem' }} justify="center">
              <IconBandage  size="16" />
              <Text size="xs">Dispositifs Médicaux</Text>
            </Group>
          ),
        },
        { value: "biologie", label: (
          <Group style={{ gap: '0.5rem' }} justify="center">
            <IconDroplet size="16" />
            <Text size="xs">Biologie</Text>
          </Group>
        ), },
        { value: "radiologie", label:  (
          <Group style={{ gap: '0.5rem' }} justify="center">
            <IconRadioactive size="16" />
            <Text size="xs">Radiologie</Text>
          </Group>
        ), },
        { value: "autres", label:  (
          <Group style={{ gap: '0.5rem' }} justify="center">
            <IconEdit size="16" />
            <Text size="xs">Autres</Text>
          </Group>
        ),},
      ],
      setActive: (value) => set({ active: value }),
      setActiveTab: (value) => set({ activeTab: value }),


  middleActiveTab: "Historique des Consultations",
  setMiddleActiveTab: (tab) => set({ middleActiveTab: tab }),

  middleActive: "Historique des Consultations",
  setMiddleActive: (value) => set({ middleActive: value }),

  getMiddleData: () => {
    const state = get();
    const middleData = [];

    if (state.selectedPatientInfo && Object.keys(state.selectedPatientInfo).length > 0) {
      const commonTabs = [
        {
          value: "Historique des Consultations",
          label: (
            <Group style={{ gap: '0.5rem' }} justify="center">
              <IconTimelineEvent size="16" />
              <Text size="xs">Historique</Text>
            </Group>
          ),
        },
        {
          value: "Documents",
          label: (
            <Group style={{ gap: '0.5rem' }} justify="center">
              <IconFolderOpen size="16" />
              <Text size="xs">Documents</Text>
            </Group>
          ),
        },
        // {
        //   value: "iAvicenne Chat",
        //   label: (
        //     <Group style={{ gap: '0.5rem' }} justify="center">
        //       <IconMessage size="16" />
        //       <Text size="xs">Chat</Text>
        //     </Group>
        //   ),
        // },

      ];

      middleData.push(...commonTabs);

      if (state.showEncouterObjects) {
        middleData.push({
          value: "Observation Médicale",
          label: (
            <Group style={{ gap: '0.5rem' }} justify="center">
              <IconStethoscope size="16" />
              <Text size="xs">Observation</Text>
            </Group>
          ),
        });
      }

      if (state.showEncouterObjects) {
        middleData.push({
          value: "Courrier Médical",
          label: (
            <Group style={{ gap: '0.5rem' }} justify="center">
              <IconMail size="16" />
              <Text size="xs">Courrier</Text>
            </Group>
          ),
        });
      }

      if (Object.keys(state.hypothesesDiagnostiques).length > 0) {
        middleData.push({
          value: "Hypotheses Diagnostiques",
          label: (
            <Group style={{ gap: '0.5rem' }} justify="center">
              <IconBrain size="16" />
              <Text size="xs">Diagnostic</Text>
            </Group>
          ),
        });
      }

      if (Object.keys(state.examensComplementaires).length > 0) {
        middleData.push({
          value: "Examens Complementaires",
          label: (
            <Group style={{ gap: '0.5rem' }} justify="center">
              <IconRadioactiveFilled size="16" />
              <Text size="xs">Examens</Text>
            </Group>
          ),
        });
      }

      if (Object.keys(state.propositionsTherapeutiques).length > 0) {
        middleData.push({
          value: "Propositions Thérapeutiques",
          label: (
            <Group style={{ gap: '0.5rem' }} justify="center">
              <IconPills size="16" />
              <Text size="xs">Traitements</Text>
            </Group>
          ),
        });
      }
    }

    return middleData;
  },

 
      opened: false,
      // Actions
      open: () => set({ opened: true }),
      close: () => set({ opened: false }),
      toggle: () => set((state) => ({ opened: !state.opened })),
    }),
    {
      name: 'patient-store', // nom pour le stockage persistant
    }
// )
)

