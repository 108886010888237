import React, { useRef, useEffect } from "react";

export const AudioVisualizer = ({ audioStream }) => {
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);

  useEffect(() => {
    if (!audioStream) return;

    // Initialisation des objets AudioContext et AnalyserNode
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256; // Taille de la FFT pour des détails moyens
    const source = audioContext.createMediaStreamSource(audioStream);
    source.connect(analyser);

    audioContextRef.current = audioContext;
    analyserRef.current = analyser;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Assure que le canvas prend la taille de son conteneur
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const renderVisualizer = () => {
      if (!analyser) return;

      analyser.getByteFrequencyData(dataArray);

      context.clearRect(0, 0, canvas.width, canvas.height);

      const barWidth = (canvas.width / bufferLength) * 1.5;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const barHeight = dataArray[i] / 4;
        context.fillStyle = "#94ADA7";
        context.fillRect(x, canvas.height - barHeight, barWidth, barHeight);
        x += barWidth + 1; // Espacement entre les barres
      }

      requestAnimationFrame(renderVisualizer);
    };

    renderVisualizer();

    return () => {
      audioContext.close();
    };
  }, [audioStream]);

  return (
      <canvas
        ref={canvasRef}
        style={{ width: "5vw", height: "5vh" }}
      ></canvas>
    // </div>
  );
};

