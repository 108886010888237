import React, { useState, useEffect, useRef } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import Markdown from "react-markdown";
import {
  Dialog,
  Group,
  Text,
  Loader,
  Center,
  Image,
  Card,
  Container,
  Textarea,
  Button,
  Stack,
  ScrollArea,
} from "@mantine/core";
import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { IconCheck, IconArrowLeft,IconAlertTriangleFilled } from "@tabler/icons-react";
import {client} from "../api/axiosClient"
import {AudioVisualizer} from "./Audiovisualizer";



const StreamComponent = ({ url }) => {
  const [fullText, setFullText] = useState("");
  const [visibleChars, setVisibleChars] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const currentNotification = useStore((state) => state.currentNotification);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      setError(null);
      setFullText("");
      setVisibleChars(0);

      try {
        const response = await fetch(url, { signal });

        if (!response.body) {
          throw new Error("ReadableStream not yet supported in this browser.");
        }

        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();

        let accumulatedText = "";
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          if (isMounted) {
            accumulatedText += value;
            setFullText((prev) => prev + value);
          }
        }
        setLoading(false);
      } catch (error) {
        if (error.name !== "AbortError" && isMounted) {
          setError(error);
          setLoading(false);
        }
      }
    };

    fetchData();

    // Afficher la notification de chargement
    notifications.update({
      id: currentNotification,
      withBorder: true,
      loading: false,
      radius: "lg",
      color: "#94ADA7",
      title: (
        <Group gap="xs">
          <Image
            fit="contain"
            src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
            alt="iAvicenne Logo"
            width={25}
            height={25}
            style={{ margin: "0", display: "block" }}
          />
          <Text fw={700}>Reflexion Terminée</Text>
          <IconCheck
            style={{ width: "1.1rem", color: "#94ADA7", height: "1.1rem" }}
          />
        </Group>
      ),

      opacity: 0.7,
      autoClose: 1000,
      withCloseButton: true,


    });

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [url]);

  useEffect(() => {
    if (visibleChars < fullText.length) {
      const timer = setTimeout(() => {
        setVisibleChars((prev) => Math.min(prev + 1, fullText.length));
      }, 3);

      return () => clearTimeout(timer);
    }
  }, [fullText, visibleChars]);

  const visibleText = fullText.slice(0, visibleChars);

  return (
    <ScrollArea h={"70vh"} offsetScrollbars scrollHideDelay={100}>
      <Card
        className="AudioChatCard"
        withCloseButton={true}
        size="xl"
        radius="12px"
        shadow="lg"
        withBorder={true}
      >
        <Text size="sm" mb="xs" fw={500} ta="left">
          {loading && (
            <Center>
              <Loader className="ChatLoader" color="#94ADA7" type="dots" />
            </Center>
          )}
          {error && <p>Error: {error.message}</p>}
          <Markdown>{visibleText}</Markdown>
        </Text>
      </Card>
    </ScrollArea>
  );
};

export function AudioDirectChat() {
  const [streamUrl, setStreamUrl] = useState("");
  const loading = useStore((state) => state.loading);
  const setLoading = useStore((state) => state.setLoading);
  const [stream, setStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false)
  const { currentNotification, setCurrentNotification } = useStore();
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);
  const audioRecorderRef = useRef(null);

  useEffect(() => {
    // Demande d'accès au micro
    const getMicrophone = async () => {
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setStream(audioStream);
      } catch (err) {
        console.error("Erreur lors de l'accès au micro :", err);
      }
    };

    getMicrophone();

    return () => {
      if (stream) {
        // Arrêt du stream lorsque le composant est démonté
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);
  
  useEffect(() => {
    const element = audioRecorderRef.current;
    if (element) {
      const handleClick = () => {
        setIsRecording((prev) => !prev);
      };

      // Ajout de l'event listener
      element.addEventListener("click", handleClick);

      // Cleanup : suppression de l'event listener
      return () => {
        element.removeEventListener("click", handleClick);
      };
    }
  }, []);
  
  const uploadAudioBlob = async (blob) => {
    const mimeType = blob.type;
    let fileExtension = "";

    switch (mimeType) {
      case "audio/webm":
        fileExtension = "webm";
        break;
      case "audio/wav":
        fileExtension = "wav";
        break;
      case "audio/mp4":
        fileExtension = "mp4";
        break;
      default:
        console.warn("Type MIME non supporté. Utilisation de 'webm' par défaut.");
        fileExtension = "webm";
    }

    const fileName = `audio.${fileExtension}`;
    const formData = new FormData();
    formData.append("file", blob, fileName);
    formData.append("practitioner_id", currentSessionInfo.practitioner.toString());
    try {
      const response = await client.post("api/audio-file/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    
      if (response.status === 201) {
        console.log("Fichier audio téléchargé avec succès");
        const transcription = await client.get(
          `api/audio-file/${response.data.id}/transcribe/`
        );
    
        if (transcription.status === 200) {
          const notificationId = notifications.show({
            id: currentNotification,
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Stack>
                <Group gap="xs">
                  <Image
                    fit="contain"
                    src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                    alt="iAvicenne Logo"
                    width={25}
                    height={25}
                    style={{ margin: "0", display: "block" }}
                  />
                  <Text fw={700}>Reflexion</Text>
                  <Loader className="ChatLoader" color="#94ADA7" type="dots" />
                </Group>
              </Stack>
            ),
            opacity: 0.7,
            autoClose: false,
            withCloseButton: true,
          });
    
          // const corrected = await client.get(
          //   `api/correct-question/?transcription=${encodeURIComponent(transcription.data.transcription)}`
          // );
          setStreamUrl(`https://www.iavicenne.fr/api/chat/direct/?message=${transcription.data.transcription}`);
          // if (corrected.status === 200) {
          //   console.log(corrected);
          //   handleTranscription(corrected.data);
          // } else {
          //   console.error(
          //     "Erreur lors de la correction de la transcription:",
          //     corrected
          //   );
          // }
        } else {
          console.error(
            "Erreur lors de la transcription du fichier audio:",
            transcription
          );
        }
      } else {
        console.error(
          "Erreur lors du téléchargement du fichier audio:",
          response
        );
      }
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier audio:", error);
    }
  }

  const handleTranscription = async (transcriptMedical) => {
    try {
      if (transcriptMedical) {
            setStreamUrl(`https://www.iavicenne.fr/api/chat/direct/?message=${transcriptMedical}`);
          } else {
            handleNotificationError("synthèse des données");
          }
      }catch (error) {
      handleNotificationError("handle Transription", error);
    }
  };


  const updateNotification = (title, loading=true) => {
   notifications.update({
    id: currentNotification,
      withBorder: true,
      loading:false,
      radius: "lg",
      color: "#94ADA7",
      title: (
        <Stack>
          <Group gap="xs">
            <Image
              fit="contain"
              src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
              alt="iAvicenne Logo"
              width={25}
              height={25}
              style={{ margin: "0", display: "block" }}
            />
            <Text fw={700}>{title}</Text>
            {loading ? <Loader className="ChatLoader" color="#94ADA7" type="dots" /> : <IconCheck style={{ width: "1.1rem", color: "#94ADA7", height: "1.1rem" }} />}
          </Group>
        </Stack>
      ),
      opacity: 0.7,
      autoClose: false,
      withCloseButton: true,
    });
  };

  const handleNotificationError = (context, response) => {
    notifications.update({
      id: currentNotification,
      withBorder: true,
      loading: false,
      radius: "lg",
      color: "#94ADA7",
      title: (
        <Group gap="xs">
          <IconAlertTriangleFilled color="red" size="25" />
          <Text fw={700}>Erreur</Text>
          <Text size="sm">
            {context}: {response.status} {response.statusText}
          </Text>
        </Group>
      ),
      opacity: 0.7,
      autoClose: 2000,
      withCloseButton: true,
    });
  };

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;

    uploadAudioBlob(blob);
  };

  return (
    <>
      <Stack>
        <Container className="ChatQuestionRecorder">
          {streamUrl && <StreamComponent url={streamUrl} />}

          <Container className="ChatRecorderElements">
            <Group  className="chatAudio" gap="xs" align="baseline" ref={audioRecorderRef}>
              <AudioRecorder
               
                onRecordingComplete={addAudioElement}
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                onNotAllowedOrFound={(err) => console.table(err)}
                downloadOnSavePress={false}
                downloadFileExtension="webm"
                mediaRecorderOptions={{
                  audioBitsPerSecond: 128000,
                }}
                showVisualizer={false}
              />
              {/* <IconArrowLeft size={14} />
              <Text size="xs">Cliquez-ici pour poser votre Question</Text> */}
                    <div className="audioVisualizer_Chat">
      {stream && isRecording && <AudioVisualizer audioStream={stream} />}
      </div>
            </Group>
          </Container>
          {/* <Container className="ChatRecorderTextArea">
            <Textarea
              className="ChatTextarea"
              variant="default"
              radius="12px"
              shadow="lg"
              label=""
              placeholder="Cliquer sur l'icone Microphone, posez votre question puis cliquez sur l'icone Enregistrer"
              autosize
              minRows={5}
              disabled
            />
          </Container> */}
        </Container>
        {loading && (
          <Center>
            <Loader className="ChatLoader" color="#94ADA7" type="dots" />
          </Center>
        )}
      </Stack>
    </>
  );
}

