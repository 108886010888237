"use client";

import { useState, useEffect, useRef } from "react";
import { useStore } from "../Store";

import {
  Stack,
  Button,
  Textarea,
  Text,
  Card,
  Group,
  Container,
  Loader,
  ScrollArea,
  Image,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import Markdown from "react-markdown";
import {
  IconArrowUp,
  IconCheck,
  IconAlertTriangleFilled,
  IconWorld,
  IconBooks,
  IconBook,
} from "@tabler/icons-react";
import { client } from "../api/axiosClient";
import { notifications } from "@mantine/notifications";

const StreamComponent = ({ url }) => {
  const [fullText, setFullText] = useState("");
  const [visibleChars, setVisibleChars] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      setLoading(true);
      setError(null);
      setFullText("");
      setVisibleChars(0);

      try {
        const response = await fetch(url, { signal });

        if (!response.body) {
          throw new Error("ReadableStream not yet supported in this browser.");
        }

        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          if (isMounted) {
            setFullText((prev) => prev + value);
          }
        }
        setLoading(false);
      } catch (error) {
        if (error.name !== "AbortError" && isMounted) {
          setError(error);
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [url]);

  useEffect(() => {
    if (visibleChars < fullText.length) {
      const timer = setTimeout(() => {
        setVisibleChars((prev) => Math.min(prev + 1, fullText.length));
      }, 3); // Ajustez ce délai pour contrôler la vitesse d'apparition

      return () => clearTimeout(timer);
    }
  }, [fullText, visibleChars]);

  const visibleText = fullText.slice(0, visibleChars);

  return (
    <ScrollArea h={"70vh"} offsetScrollbars scrollHideDelay={100}>
      <Card
        className="ChatCard"
        withCloseButton={true}
        size="xl"
        radius="12px"
        shadow="lg"
        withBorder={true}
      >
        <Text size="sm" mb="xs" fw={500} ta="left">
          {loading && (
            <Group gap="xs">
              <Image
                fit="contain"
                src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                alt="iAvicenne Logo"
                width={25}
                height={25}
                style={{ margin: "0", display: "block" }}
              />
              <Text fw={700}>Revue de la litterature sur Semantic Scholar</Text>
              <Loader className="ChatLoader" color="#94ADA7" type="dots" />
            </Group>
          )}
          {error && <p>Error: {error.message}</p>}
          <Markdown>{visibleText}</Markdown>
        </Text>
      </Card>
    </ScrollArea>
  );
};

export const ScholarChat = () => {
  const {
    currentNotification,
    setCurrentNotification,
    webVariant,
    scholarVariant,
    pubmedVariant,
    setChat,
    setWebVariant,
    setScholarVariant,
    setPubmedVariant
  } = useStore();

  const [streamUrl, setStreamUrl] = useState("");
  const form = useForm({
    initialValues: {
      message: "",
    },
    validate: {
      message: (value) => (value.length < 3 ? "Votre message est vide" : null),
    },
  });
  const messagesContainerRef = useRef(null);

  const scrollToBottom = () => {
    messagesContainerRef.current?.scrollTo({
      top: messagesContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleSubmit = async (value) => {
    try {
      if (value.message) {
        console.log(value.message);
        // const response = client.get(`api/chat/reviewList/?message=${value.message}`)
        // if (response.status===200){
        //   const articles = response.data.articles
        //   setStreamUrl(
        //     `https://www.iavicenne.fr/api/chat/review/?message=${value.message}&articles=${articles}`
        //   );

        // }
        // else(console.log ('erreur du stream de la revue de litterature '))
        // const notificationId = notifications.show({
        //   id: currentNotification,
        //     withBorder: true,
        //     loading:false,
        //     radius: "lg",
        //     color: "#94ADA7",
        //     title: (
        //       <Stack>
        //         <Group gap="xs">
        //           <Image
        //             fit="contain"
        //             src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
        //             alt="iAvicenne Logo"
        //             width={25}
        //             height={25}
        //             style={{ margin: "0", display: "block" }}
        //           />
        //           <Text fw={700}>Reflexion en Cours</Text>
        //           <Loader className="ChatLoader" color="#94ADA7" type="dots" />
        //         </Group>
        //       </Stack>
        //     ),
        //     opacity: 0.7,
        //     autoClose: false,
        //     withCloseButton: true,
        //   });
        setStreamUrl(
          `https://www.iavicenne.fr/api/chat/reviewScholar/?message=${value.message}`
        );
      }
    } catch (error) {
      handleNotificationError("handle Transription", error);
    }
  };

  const updateNotification = (title, loading = true) => {
    notifications.update({
      id: currentNotification,
      withBorder: true,
      loading: false,
      radius: "lg",
      color: "#94ADA7",
      title: (
        <Stack>
          <Group gap="xs">
            <Image
              fit="contain"
              src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
              alt="iAvicenne Logo"
              width={25}
              height={25}
              style={{ margin: "0", display: "block" }}
            />
            <Text fw={700}>{title}</Text>
            {loading ? (
              <Loader className="ChatLoader" color="#94ADA7" type="dots" />
            ) : (
              <IconCheck
                style={{ width: "1.1rem", color: "#94ADA7", height: "1.1rem" }}
              />
            )}
          </Group>
        </Stack>
      ),
      opacity: 0.7,
      autoClose: false,
      withCloseButton: true,
    });
  };

  const handleNotificationError = (context, response) => {
    notifications.update({
      id: currentNotification,
      withBorder: true,
      loading: false,
      radius: "lg",
      color: "#94ADA7",
      title: (
        <Group gap="xs">
          <IconAlertTriangleFilled color="red" size="25" />
          <Text fw={700}>Erreur</Text>
          <Text size="sm">
            {context}: {response.status} {response.statusText}
          </Text>
        </Group>
      ),
      opacity: 0.7,
      autoClose: 2000,
      withCloseButton: true,
    });
  };

  return (
    <>
      <Stack>
        <Container className="ChatStreamContainer" ref={messagesContainerRef}>
          {streamUrl && <StreamComponent url={streamUrl} />}
          <form
            className="ChatForm"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(form.values);
            }}
          >
            <Textarea
              className="ChatTextarea"
              variant="default"
              radius="12px"
              shadow="lg"
              label=""
              placeholder="Saisissez votre Question Médicale ici... (Recherche Semantic Scholar)"
              {...form.getInputProps("message")}
              autosize
              minRows={5}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(form.values);
                }
              }}
            />
            <Button
              className="ChatSubmit"
              size="xs"
              variant={pubmedVariant}
              color="#94ADA7"
              radius="12px"
              shadow="lg"
              onClick={() => {
                setChat("showPubmedChat");
                setWebVariant("light");
                setScholarVariant("light");
                setPubmedVariant("filled");
              }}
              style={{
                position: "absolute",
                right: "220px",
                top: "20%",
                transform: "translateY(-50%)",
              }}
            >
              <IconBooks/>
            </Button>
            <Button
              className="ChatSubmit"
              size="xs"
              variant={scholarVariant}
              color="#94ADA7"
              radius="12px"
              shadow="lg"
              onClick={() => {
                setChat("showAudioDirectChat");
                setWebVariant("light");
                setScholarVariant("light");
                setPubmedVariant("light");
              }}
              style={{
                position: "absolute",
                right: "150px",
                top: "20%",
                transform: "translateY(-50%)",
              }}
            >
              <IconBook/>
            </Button>
            <Button
              className="ChatSubmit"
              size="xs"
              variant={webVariant}
              color="#94ADA7"
              radius="12px"
              shadow="lg"
              onClick={() => {
                setChat("showAudioChat");
                setWebVariant("filled");
                setScholarVariant("light");
                setPubmedVariant("light");
              }}
              style={{
                position: "absolute",
                right: "80px",
                top: "20%",
                transform: "translateY(-50%)",
              }}
            >
              <IconWorld />
            </Button>
            <Button
              className="ChatSubmit"
              type="submit"
              radius="12px"
              color="#94ADA7"
              size="xs"
              // onClick={handleSubmit}
              style={{
                position: "absolute",
                right: "10px",
                top: "20%",
                transform: "translateY(-50%)",
              }}
              disabled={!form.values.message.trim()}
            >
              <IconArrowUp />
            </Button>
          </form>
        </Container>
      </Stack>
    </>
  );
};
