import { useState, useEffect } from "react";
import { CloudDrizzle } from "react-feather";
import { Button, Group } from "@mantine/core";
import { useStore } from "../Store";

function SessionStopped({ startSession }) {
  const [isActivating, setIsActivating] = useState(false);

  function handleStartSession() {
    if (isActivating) return;

    setIsActivating(true);
    startSession();
  }

  return (
    <div className="flex items-center justify-center w-full h-full">
      <Button
        className='mailButton'
        variant="light"
        color="#94ADA7"
        radius="12px"
        onClick={handleStartSession}
        icon={<CloudDrizzle height={16} />}
      >
        {isActivating ? "starting session..." : "start session"}
      </Button>
    </div>
  );
}

function SessionActive({ stopSession, sendTextMessage }) {
  const [message, setMessage] = useState("");

  function handleSendClientEvent() {
    sendTextMessage(message);
    setMessage("");
  }

  return (
    <Group gap="lg" justify="center">
      <div className="flex items-center justify-center w-full h-full gap-4">
        <input
          onKeyDown={(e) => {
            if (e.key === "Enter" && message.trim()) {
              handleSendClientEvent();
            }
          }}
          type="text"
          placeholder="send a text message..."
          className="border border-gray-200 rounded-full p-4 flex-1"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          onClick={() => {
            if (message.trim()) {
              handleSendClientEvent();
            }
          }}
          className='mailButton'
          variant="light"
          color="#94ADA7"
          radius="12px"
        >
          send text
        </Button>
        <Button
          onClick={stopSession}
          className='mailButton'
          variant="light"
          color="#94ADA7"
          radius="12px"
        >
          disconnect
        </Button>
      </div>
    </Group>
  );
}

export default function SessionControls({
  startSession,
  stopSession,
  sendClientEvent,
  sendTextMessage,
  serverEvents,
  isSessionActive,
}) {
  return (
    <div className="flex gap-4 border-t-2 border-gray-200 h-full rounded-md">
      {isSessionActive ? (
        <SessionActive
          stopSession={stopSession}
          sendClientEvent={sendClientEvent}
          sendTextMessage={sendTextMessage}
          serverEvents={serverEvents}
        />
      ) : (
        <SessionStopped startSession={startSession} />
      )}
    </div>
  );
}
