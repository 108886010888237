// components/MiddleColumn.js

import { useState } from "react";
import {
  Grid,
  Divider,
  SegmentedControl,
  Tabs,
  Card,
  Group,
  Button,
  Stack,
  Title,
} from "@mantine/core";
import { useStore } from "../Store";
import {
  IconPillFilled,
  IconSwitchVertical,
  IconFileArrowRight,
  IconDropletFilled,
  IconRadioactiveFilled,
  IconBandageFilled,
  IconFileCertificate,
  IconMicrophone,
  IconMicrophone2,
  IconTypography,
  IconTextCaption,
  IconBook,
  IconBook2,
  IconWebhook,
  IconWorld,
  IconTextPlus,
} from "@tabler/icons-react";
import { HistoriqueTimeline } from "../components/Historique";
import { ObservationForm } from "../components/Observation";
import { CourrierMedical } from "../components/GenerateCourrier.js";
import { useToggle } from "@mantine/hooks";
import { RecordQuestion } from "../components/AudioChat";
import { Chat } from "../components/iAvicenneWebChat";
import { ShowHypotheses } from "../components/HypothesesDiag";
import { ShowExamensComplementaires } from "../components/ExamensComplementaires";
import { ShowPropositionsTherapeutiques } from "../components/PropositionsTherapeutiques";
import { ShowMedicament } from "../components/ShowMedicamentDetail";
import { AntecedentsType } from "../components/FloatingAntecedentsType";
import { SearchAntecedents } from "../components/SearchAntecedents";
import { DownloadFile } from "../components/DownloadFile";
import { PatientForm } from "../components/UpdatePatient";
import { NewPatientForm } from "../components/AddNewPatient";
import { RenewOrdonnanceType } from "../components/AddOrdonnanceType";
import { RenewServiceRequest } from "../components/AddServiceRequestType";
import { handleAntecedentClickInternal } from "../components/AddAntecedents";
import { AudioDirectChat } from "../components/AudioDirectChat";
import { DirectChat } from "../components/iAvicenneDirectChat";
import { ScholarChat } from "../components/iAvicenneScholarChat";
import { PubmedChat } from "../components/iAvicennePubmedChat"; 
import { ConsolePage } from "../openaiWebRTC/App";
// import { ConsolePage_2 } from "../openaiRTC/pages/ConsolePage";

export function MiddleColumn() {
  const {
    middleActive,
    middleActiveTab,
    setMiddleActive,
    setMiddleActiveTab,
    selectedMedicamentDetail,
    ordonnancetypeData,
    antecedentSearchType,
    selectedPatientInfo,
    setSelectedPatientInfo,
    setShowAntecedentsSearch,
    getMiddleData,
    chat,
  } = useStore();

  const middleData = getMiddleData();
  // const [chat, toggleChat] = useToggle(["showAudioChat","AudioDirectChat"]);
  // const [variant, toggleVariant] = useToggle(["filled","light"]);
  const handleHistoriqueControlChange = (value) => {
    setMiddleActive(value);
    setMiddleActiveTab(value);
  };

  const handleFormValuesChange = (values) => {
    setFormValues(values);
  };
  const handleAntecedentClick = (value) => {
    handleAntecedentClickInternal(
      value,
      antecedentSearchType,
      selectedPatientInfo,
      setSelectedPatientInfo,
      setShowAntecedentsSearch
    );
  };

  const [values, setFormValues] = useState({});

  return (
    <Grid.Col span={5} className="historiqueGridCol">
      <Divider my="xs" label={middleActiveTab} labelPosition="center" />
      <SegmentedControl
        data={middleData}
        value={middleActive}
        onChange={handleHistoriqueControlChange}
        color="#94ADA7"
        radius="12px"
        size="xs"
        withItemsBorders={false}
        transitionDuration={500}
        transitionTimingFunction="linear"
        fullWidth
        style={{ marginBottom: 20 }}
      />
      <Tabs value={middleActiveTab} onChange={setMiddleActiveTab} radius="12px">
        <Tabs.Panel value="Historique des Consultations">
          <div className="HistoriqueTimeline">
            <HistoriqueTimeline />
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="Observation Médicale">
          <Card radius="12px" shadow="lg" withBorder={true}>
            <ObservationForm onValuesChange={handleFormValuesChange} />
          </Card>
        </Tabs.Panel>

        <Tabs.Panel value="Courrier Médical">
          <Card radius="12px" shadow="lg" withBorder={true}>
            <CourrierMedical />
          </Card>
        </Tabs.Panel>

        <Tabs.Panel value="iAvicenne Research" className="iAvicenne-Chat">
          <Group>
            {chat === "showAudioChat" && (<Stack><RecordQuestion/><Chat/></Stack>)}
            {chat === "showAudioDirectChat" &&(<Stack><AudioDirectChat/><DirectChat/></Stack>)}
            {chat === "showScholarChat" &&(<Stack><ScholarChat/></Stack>)}
            {chat === "showPubmedChat" &&(<Stack><PubmedChat/></Stack>)}
          </Group>
        </Tabs.Panel>

        <Tabs.Panel value="iAvicenne" className="realtimeConsoleWRC">
        <ConsolePage/>
        </Tabs.Panel>
        <Tabs.Panel value="Hypotheses Diagnostiques">
          <ShowHypotheses />
        </Tabs.Panel>

        <Tabs.Panel value="Examens Complementaires">
          <ShowExamensComplementaires />
        </Tabs.Panel>

        <Tabs.Panel value="Propositions Thérapeutiques">
          <ShowPropositionsTherapeutiques />
        </Tabs.Panel>
        <Tabs.Panel value="Medicament">
          {selectedMedicamentDetail && <ShowMedicament />}
        </Tabs.Panel>

        <Tabs.Panel value="Modifier les Antecedents">
          <AntecedentsType />
          <SearchAntecedents
            data-autofocus
            className="SearchAntecedents"
            handleAntecedentClick={handleAntecedentClick}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Documents">
          <DownloadFile />
        </Tabs.Panel>
        <Tabs.Panel value="Données Patient">
          <PatientForm />
        </Tabs.Panel>
        <Tabs.Panel value="Nouveau Patient">
          <NewPatientForm />
        </Tabs.Panel>
        <Tabs.Panel value="Ordonnance Type">
          <Stack>
            {ordonnancetypeData?.map((listItem) => (
              <Card shadow="lg" radius="12px" p="md" withBorder={true} fullWidth>
                <Group justify="space-between">
                  <Group>
                    <Button
                      // className="printButton"
                      variant="outline"
                      size="xs"
                      color="#94ADA7"
                      radius="12px"
                      style={{ boxShadow: "0 2px 5px #bebebe" }}
                    >
                      {" "}
                      <IconFileArrowRight size={16} />
                      {listItem.type === "Biologie" && (
                        <IconDropletFilled size={12} />
                      )}
                      {listItem.type === "Radiologie" && (
                        <IconRadioactiveFilled size={12} />
                      )}
                      {listItem.type === "DispositifMedical" && (
                        <IconBandageFilled size={12} />
                      )}
                      {listItem.type === "OrdonnanceLibre" && (
                        <IconFileCertificate size={12} />
                      )}
                    </Button>
                    <Title size="h4" tt="capitalize">
                      {listItem.indication}
                    </Title>
                  </Group>
                  {listItem?.ServiceRequest ? (
                    <RenewServiceRequest
                      ordoTypeId={listItem.id}
                      ServiceType={listItem?.ServiceRequest[0].categoryContent}
                    />
                  ) : (
                    <RenewOrdonnanceType ordoTypeId={listItem.id} />
                  )}
                </Group>
              </Card>
            ))}
          </Stack>
        </Tabs.Panel>
      </Tabs>
    </Grid.Col>
  );
}
